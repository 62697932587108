import * as moment from "moment";

/**
 * @description 将表单项树结构转换为扁平结构
 * @param formItemTree {Array}
 * @return Array
 */
export function flatFormItem(formItemTree) {
  console.log(formItemTree)
  return formItemTree.reduce((result, item) => {
    console.log(item)
    if (item.name === "SpanLayout") {
      return result.concat(flatFormItem(item.props.items));
    } else {
      return result.concat(item);
    }
  }, []);
}

export function formatTime(row, column) {
  if (row[column.property] === null) {
    return "";
  }
  return moment(row[column.property]).format("YYYY-MM-DD HH:mm:ss");
}

export function formatBusinessStatus(row, column) {
  const map = {
    "1": "审核中",
    "2": "已撤销",
    "3": "驳回",
    "4": "办结",
  };
  return map[row[column.property]];
}
export function distance(start) {
  // 计算时间戳之间的毫秒差
  const millisecondsDifference = Math.abs(new Date().getTime() - start);

  // 计算天数
  const secondsInADay = 1000 * 60 * 60 * 24;
  const days = Math.floor(millisecondsDifference / secondsInADay);

  // 剩余的毫秒数
  const remainingMillisecondsAfterDays = millisecondsDifference % secondsInADay;

  // 计算小时
  const secondsInAnHour = 1000 * 60 * 60;
  const hours = Math.floor(remainingMillisecondsAfterDays / secondsInAnHour);

  // 剩余的毫秒数
  const remainingMillisecondsAfterHours = remainingMillisecondsAfterDays % secondsInAnHour;

  // 计算分钟
  const secondsInAMinute = 1000 * 60;
  const minutes = Math.floor(remainingMillisecondsAfterHours / secondsInAMinute);

  // 剩余的毫秒数
  // const remainingMillisecondsAfterMinutes = remainingMillisecondsAfterHours % secondsInAMinute;

  // 计算秒
  // const seconds = Math.floor(remainingMillisecondsAfterMinutes / 1000);

  // 构建结果字符串
  let result = '';
  if (days > 0) {
    result += days + '天 ';
  }
  if (hours > 0 || days > 0) { // 如果有天数，就显示小时
    result += hours + '小时 ';
  }
  if (minutes > 0 || hours > 0 || days > 0) { // 如果有小时或天数，显示分钟
    result += minutes + '分';
  }
  // result += seconds + '秒'; // 总是显示秒

  return result.trim(); // 去掉末尾多余的空格
}